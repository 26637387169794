<template>
  <div style="display: flex; justify-content: center; align-items: center; ">

    <table>
      <tr>
        <td>姓氏:</td>
        <td>
          <el-input
              class="optional"
              size="mini"
              maxlength="2"
              v-model="surname"
              placeholder="请输入姓氏"
          ></el-input>
        </td>
      </tr>

      <tr>
        <td>性别:</td>
        <td>
          <el-select
              v-model="sex"
              size="mini"
              placeholder="请选择娃儿的性别"
              style="width: 180px"
              clearable
              filterable
          >
            <el-option
                v-for="item in sexOptions"
                :key="item"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </td>
      </tr>
      <tr>
        <td>生日:</td>
        <td>
          <el-date-picker v-model="birthday" clearable type="datetime" value-format="YYYY-MM-DD HH:mm:ss"
                          placeholder="选择生日"
                          style="width:195px;margin-right:5px"></el-date-picker>
        </td>
      </tr>
      <tr>
        <td>附加:</td>
        <td>
          <el-input
              class="optional"
              size="mini"
              maxlength="20"
              v-model="new_name_prompt"
              placeholder="请输入一些附加要求"
          ></el-input>
        </td>
      </tr>
      <tr>
        <td>农历:</td>
        <td>
          {{ lunarBirthday }}
        </td>
      </tr>
    </table>
  </div>
  <fortune-card
      :loading="loading"
      :title="title"
      :bt_name="bt_name"
      :result="result"
      :startEventStream="startEventStream"
  ></fortune-card>
</template>

<script>
import DivinationMixin from './DivinationMixin';
import FortuneCard from '@/components/tarot/FortuneCard.vue';

export default {
  components: {
    FortuneCard
  },
  mixins: [DivinationMixin],
  data() {
    return {
      surname: "江",
      sex: "男",
      new_name_prompt: "",
      prompt_type: 'new_name',
      birthday: "1997-05-28 00:00:00",
      sexOptions: ["男", "女"],
      lunarBirthday: '龙年 庚辰年 七月十八 巨蟹座',

      loading: false,
      title: '占卜结果',
      result: "",
      tmp_result: '',
      bt_name: '一键预测',
    }
  },
  methods: {
    startEventStream() {
      this.startEventStreamCommon({
        inputValidation: () => !!this.surname && !!this.sex && !!this.birthday,
        errorMessage: '请输入姓氏、性别和对方的生日',
        createRequestBody: () => ({
          prompt_type: this.prompt_type,
          birthday: this.birthday.valueOf(),
          new_name: {
            surname: this.surname,
            sex: this.sex,
            birthday: this.birthday.valueOf(),
            new_name_prompt: this.new_name_prompt
          }
        })
      });
    }
  }
}
</script>

<style>


div table tr td:nth-child(0) {
  text-align: right;
}

div table tr td:nth-child(1) {
  text-align: left;
}

</style>