// router.js

// import Vue from 'vue';
// import Router from 'vue-router';
import {createRouter, createWebHistory} from 'vue-router';
import tarotVue from '../components/tarot/tarotVue.vue'; // 导入塔罗牌组件
import baZi from '../components/tarot/baZi.vue'; // 导入生辰八字组件
import yinYuan from '../components/tarot/yinYuan.vue'; // 导入姻缘组件
import qiMing from '../components/tarot/qiMing.vue'; // 导入姻缘组件
import meiHua from '../components/tarot/meiHua.vue'; // 导入姻缘组件
import jieMeng from '../components/tarot/jieMeng.vue'; // 导入姻缘组件
import wuGe from '../components/tarot/wuGe.vue'; // 导入姻缘组件


const routes = [
    {path: '/', redirect: '/yinYuan'}, // 默认重定向到塔罗牌
    {path: '/tarot', name: 'tarot', component: tarotVue},
    {path: '/baZi', name: 'baZi', component: baZi},
    {path: '/yinYuan', name: 'yinYuan', component: yinYuan},
    {path: '/meiHua', name: 'meiHua', component: meiHua},
    {path: '/qiMing', name: 'qiMing', component: qiMing},
    {path: '/jieMeng', name: 'jieMeng', component: jieMeng},
    {path: '/wuGe', name: 'wuGe', component: wuGe},
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
