<template>
  <span>生日：</span>
  <el-date-picker v-model="birthday" clearable type="datetime" value-format="YYYY-MM-DD HH:mm:ss"  placeholder="选择生日"
                  style="width:195px;margin-right:5px"></el-date-picker>
  <br>
  <p>农历: {{ lunarBirthday }}</p>
  <fortune-card
      :loading="loading"
      :title="title"
      :bt_name="bt_name"
      :result="result"
      :startEventStream="startEventStream"
  ></fortune-card>
</template>


<script>
import DivinationMixin from "@/components/tarot/DivinationMixin";
import FortuneCard from '@/components/tarot/FortuneCard.vue';

export default {
  components: {
    FortuneCard
  },
  mixins: [DivinationMixin],
  data() {
    return {
      birthday: "1997-05-28 00:00:00",
      lunarBirthday: '龙年 庚辰年 七月十八 巨蟹座',
      prompt_type: 'birthday',
      loading: false,
      tmp_result: '',
      result: '',
      title: '占卜结果',
      bt_name: '开始占卜',

    }
  },
  methods: {
    startEventStream() {
      this.startEventStreamCommon({
        inputValidation: () => !!this.birthday ,
        errorMessage: '请输入生日',
        createRequestBody: () => ({
          prompt_type: this.prompt_type,
          birthday: this.birthday.valueOf(),
        })
      });
    }
  },



}
</script>

<style>
.fortune-telling {
  text-align: center;
  padding: 20px;
}




</style>