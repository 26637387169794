<!-- NavigationMenu.vue -->

<template>
  <el-menu :default-active="activeMenu" mode="horizontal" >
    <el-menu-item index="{{i}}" v-for="(item, i) in nav" :key="i">
      <router-link :to="{ name: item.name }">{{ item.zh }}</router-link>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      nav: [
        {path: '/qiMing', name: 'qiMing', zh: '起名'},
        {path: '/yinYuan', name: 'yinYuan', zh: '姻缘'},
        {path: '/tarot', name: 'tarot', zh: '塔罗牌'},
        {path: '/baZi', name: 'baZi', zh: '生辰八字'},
        {path: '/wuGe', name: 'wuGe', zh: '姓名五格'},
        {path: '/jieMeng', name: 'jieMeng', zh: '周公解梦'},
        {path: '/meiHua', name: 'meiHua', zh: '梅花易数'},
      ],
      activeMenu: 'qiMing', // 默认选中的菜单项
    };
  },
};
</script>

<style>

.el-menu {
  width: 100%;
  justify-content: center; /* 水平居中 */
}

</style>
