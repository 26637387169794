// DivinationMixin.js
export default {
    methods: {
        decodeUnicode(encodedString) {
            return encodedString.replace(/\\u[\dA-Fa-f]{4}/g, (match) => {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
            });
        },
        async startEventStreamCommon(options) {
            // 公共逻辑
            if (!options.inputValidation()) {
                this.$message.error(options.errorMessage);
                return;
            }

            let body = JSON.stringify(options.createRequestBody());

            this.loading = true;
            this.result = '占卜中...';

            try {
                const response = await fetch(`${this.API_BASE}/api/divination`, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const reader = response.body.getReader();
                    let data = '';
                    let done = false;
                    while (!done) {
                        const { done, value } = await reader.read();
                        if (done) break;
                        data += this.decodeUnicode(new TextDecoder('utf-8').decode(value));
                        this.result = data; // 逐步更新数据
                    }
                } else {
                    console.error('请求失败');
                }
            } catch (error) {
                console.error(error);
                this.result = error.message || '占卜失败';
            } finally {
                this.loading = false;
            }
        }
    }
};
