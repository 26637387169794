
import { createApp } from 'vue';
import elementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 导入 CSS 样式
import lang from 'element-plus/dist/locale/zh-cn'; // 导入 CSS 样式
// import lang from 'element-plus/lib/locale/lang/zh-cn';

// import 'element-plus/dist/theme-chalk/index.css';
// import lang from 'element-plus/lib/locale/lang/zh-cn'; // 导入中文语言
import appVue from './App.vue';
import router from './router';
// import {zhCn} from "element-plus/es/locale.mjs"; // 导入 Vue Router


const app = createApp(appVue);
app.config.globalProperties.API_BASE = 'https://divine-ffoedrusht.cn-hangzhou.fcapp.run';
// app.config.globalProperties.API_BASE = 'http://localhost:18000';
// app.config.globalProperties.API_BASE = 'http://localhost:8009';
app.use(router); // 使用 Vue Router
// app.use(elementPlus);
app.use(elementPlus, { locale: lang });
// app.use(locale);
app.mount('#app');

