<template>
  <div>
    <el-input
        class="custom-input"
        maxlength="200"
        v-model="prompt"
        placeholder="请输入您的梦境"
        style="width: 400px;"
    ></el-input>
  </div>
  <fortune-card
      :loading="loading"
      :title="title"
      :bt_name="bt_name"
      :result="result"
      :startEventStream="startEventStream"
  ></fortune-card>
</template>

<script>
import DivinationMixin from "@/components/tarot/DivinationMixin";
import FortuneCard from '@/components/tarot/FortuneCard.vue';

export default {
  components: {
    FortuneCard
  },
  mixins: [DivinationMixin],
  data() {
    return {
      prompt: "",
      prompt_type: 'dream',
      title: '占卜结果',
      bt_name: '开始占卜',
      tmp_result: '',
      loading: false,
      result: '',
    }
  },
  methods: {
    startEventStream() {
      this.startEventStreamCommon({
        inputValidation: () => !!this.prompt,
        errorMessage: '请输入您的梦境',
        createRequestBody: () => ({
          prompt: this.prompt,
          prompt_type: this.prompt_type,
        })
      });
      // ... 省略其它特定逻辑
    }
  },
}
</script>

<style>
.custom-input {
  height: 150px;
  border-radius: 50px; /* 设置输入框边框为椭圆形 */
}

.custom-input input::placeholder {
  /* 居中显示 placeholder */
  text-align: center;
}

.fortune-button:hover {
  background-color: #ff6600; /* 鼠标悬停时颜色变化 */
}
</style>