<!-- FortuneCard.vue -->
<template>
  <el-card class="result">
    <template v-if="loading" #header>
      <b>{{ title }}</b>
    </template>
    <template v-else #header>
      <button class="fortune-button" @click="startEventStream"><b>{{ bt_name }}</b></button>
    </template>

    <div>
      {{ result }}
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    title: String,
    bt_name: String,
    result: String,
    startEventStream: Function
  }
};
</script>

<style scoped>
/* 根据需要添加样式 */
</style>
