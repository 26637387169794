<template>
  <div>
    <h4>请随机输入两个 0-1000 的数字</h4>
    <span>数字一: </span>
    <el-input-number v-model="number" :min="min" :max="max"></el-input-number>
    <br>
    <br>
    <span>数字二: </span>
    <el-input-number v-model="number1" :min="min" :max="max"></el-input-number>
  </div>
  <fortune-card
      :loading="loading"
      :title="title"
      :bt_name="bt_name"
      :result="result"
      :startEventStream="startEventStream"
  ></fortune-card>

</template>

<script>
import DivinationMixin from "@/components/tarot/DivinationMixin";
import FortuneCard from '@/components/tarot/FortuneCard.vue';

export default {
  components: {
    FortuneCard
  },
  mixins: [DivinationMixin],
  data() {
    return {
      number: 0,
      number1: 0,
      min: 0,
      max: 1000,
      prompt_type: 'plum_flower',
      title: '占卜结果',
      bt_name: '开始占卜',
      tmp_result: '',
      loading: false,
      result: '',
    }
  },

  created() {
    // 在组件创建时生成随机数
    this.generateRandomNumbers();
  },
  methods: {
    generateRandomNumbers() {
      // 生成随机数并分配给number1和number2
      do {
        this.number = Math.floor(Math.random() * (this.max + 1));
        this.number1 = Math.floor(Math.random() * (this.max + 1));
      } while (this.number1 === this.number);
    },
    startEventStream() {
      this.startEventStreamCommon({
        inputValidation: () => !!this.number || this.number1,
        errorMessage: '请随机输入两个数字',
        createRequestBody: () => ({
          prompt_type: this.prompt_type,
          number: this.number,
          number1: this.number1,
        })
      });
      // ... 省略其它特定逻辑
    }
  }
}
</script>

<style>
</style>