<template>
  <div class="vue-route-transition" style="background-color: rgb(32, 36, 73);">
    <p class="tips">
      本内容仅供娱乐，不对未成年人提供服务。本网页严格按照相关法律法规和用户协议对您的信息进行全方位保护，请放心使用！
    </p>
    <div class="tarot-play">
      <div class="header-img"></div>
      <div class="step-content">
        <div class="entrance-box">
          <div class="init-box">
            <img :id="getImgId(index)" :src="cardImage" :onclick="checkCard" :style="getImageStyle(index)"
                 v-for="index in 78"
                 :key="index">
          </div>
        </div>
        <div class="init-action">
          <div class="init-desc">
            <img src="https://static.shengri.cn/uploads/QA_mp/ic_fangxing_left@2x.png?imageslim"
                 class="init-icon" v-if="imgState!=2 && imgState!=3 && card.length==0">
            <div class="desc-content" v-if="imgState==1">
              <p>来到这里，心中大概有些困惑吧 <br>或许，这里有你的答案</p>
              <p class="start">带着你的困惑，点击“开始”</p>
            </div>
            <div class="desc-content" v-else-if="imgState==3 || imgState==2">
              <p>正在洗牌....</p>
            </div>
            <div class="desc-content" v-else-if="imgState>3 && card.length==0">
              <p>此刻， <br> 请深吸壹口气，<br> 凭直觉选取3张神秘塔罗</p>
            </div>

            <img src="https://static.shengri.cn/uploads/QA_mp/ic_fangxing_right@2x.png?imageslim"
                 class="init-icon" v-if="imgState!=2 && imgState!=3 && card.length==0">
          </div>
          <div class="init-desc" v-if="imgState==1">
            <div class="shuffle-wrapper">
              <div class="shuffle-btn" @click="startDivination"> 开始</div>
            </div>
          </div>
          <div class="shuffle-action" v-if="card.length>0">
            <div class="shuffle-result">
              <div class="shuffle-driver-ico">
                <!--                <img-->
                <!--                    src="https://static.shengri.cn/uploads/QA_mp/ic_rectangle@3x.png?imageslim"-->
                <!--                    class="ico-center"><img-->
                <!--                  src="https://static.shengri.cn/uploads/QA_mp/ic_rectangle@3x.png?imageslim"-->
                <!--                  class="ico-center">-->
              </div>
              <div class="tarot-result">
                <div class="result-item-wrapper">
                  <div class="choose-tarot-wrapper">過去</div>
                  <p class="tarot-name"></p></div>
                <div class="result-item-wrapper">
                  <div class="choose-tarot-wrapper">現在</div>
                  <p class="tarot-name"></p></div>
                <div class="result-item-wrapper">
                  <div class="choose-tarot-wrapper">未来</div>
                  <p class="tarot-name"></p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      card: [], // 槽位
      imgState: 8,
      prompt_type: 'tarot',
      cardImage: this.imgState < 8 ? "https://static.shengri.cn/uploads/QA_mp/slim/ic_arrow_Card@3x.png?imageslim" : "https://static.shengri.cn/uploads/QA_mp/slim/ic_arrow_Card_heng@3x.png?imageslim",
    };
  },

  mounted() {

  },
  methods: {
    startDivination() {
      console.log("开始占卜");
      this.shuffle()
    },
    checkCard() {
      if (this.imgState < 8) {
        return
      }
      let i = this.getRandom(78, 1)
      this.card.push(i)
      console.log(this.card)
      if (this.card.length == 3) {
        console.log("开始占卜.....")
      }


    },
    getImgId(index) {
      return "img" + index
    },
    shuffle() {
      // 合牌
      this.coLicensing()
      //缩小牌
      setTimeout(() => {
        this.shrinkCard()
      }, 1000);
      //
      // // 开始洗牌
      setTimeout(() => {
        this.shuffleCard()
      }, 3000);
      // this.isShuffling = true;
      // // 在一定时间后结束洗牌动画
      // setTimeout(() => {
      //   this.isShuffling = false;
      // }, 3000); // 3秒后结束洗牌动画，根据需要调整时长
      //摆牌
    },
    //合牌动画
    coLicensing() {
      this.imgState = 2;
    },

    //缩小牌动画
    shrinkCard() {
      this.imgState = 3
    },
    shuffleCard() {
      this.imgState = 4
      setTimeout(() => {
        this.imgState = 5
      }, 2000);
      setTimeout(() => {
        this.imgState = 6
      }, 2000);
      setTimeout(() => {
        this.imgState = 7
      }, 2000);
      setTimeout(() => {
        this.imgState = 8

      }, 3000);


    },
    //摆牌
    playingCard() {

    },


    getImageStyle(index) {
      let style = {}
      if (this.imgState == 1) {
        switch (index) {
          case 1:
            style.transform = `rotate(40deg) scale(.8)`
            break
          case 2:
            style.transform = `rotate(20deg) scale(.9)`
            break
          case 3:
            style.transform = `rotate(-40deg) scale(.8)`
            break
          case 4:
            style.transform = `rotate(-20deg) scale(.9)`
            break
          case 5:
            style.transform = `rotate(0) scale($1)`
            break
          default:
            style.display = `none`
        }
      } else if (this.imgState == 2) {
        style.transform = null
        style.animation = `entranceAni${index} 1s linear forwards`
      } else if (this.imgState == 3) {
        switch (index) {
          case 1:
            style.animation = `shuffle 3s ease`
            style.transform = `rotate(180deg)`
            style.transform = `translate(50px, 50px)`
            break
          default:
            style.display = `none`
        }
      } else if (this.imgState == 4 || this.imgState == 6) {

        let r = this.getRandom(300, -300)
        let s = this.getRandom(300, -100)
        // console.log(`${this.imgState}-${r}-${s}`)

        style.transform = `rotate(${index}deg)`
        style.transform = `translate(${r}px, ${s}px)`
      } else if (this.imgState == 5 || this.imgState == 7) {
        style.transform = `rotate(180deg)`
        style.transform = `translate(50px, 50px)`

      }
      if (this.imgState >= 3 && this.imgState <= 7) {
        style.width = `70px`
        style.height = `100px`
      }
      if (this.imgState > 7) {
        style.height = `70px`
        style.width = `100px`
        let a = 0.8
        if (index <= 26) {
          style.top = `${index * a}rem`
        } else if (index <= 52) {
          style.marginLeft = `150px`
          style.top = `${(Math.abs(index - 26)) * a}rem`

        } else {
          style.marginLeft = `300px`
          style.top = `${(Math.abs(index - 52)) * a}rem`

        }

      }
      // console.log(style)
      return style
    },
    getRandom(max, min) {
      return Math.floor(Math.random() * (max - min) + min);
    },
  },


}
</script>

<style scoped>

.vue-route-transition {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  backface-visibility: hidden;
}


.init-desc {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.init-icon {
  width: 20px;
}

.desc-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.shuffle-btn {
  text-align: center;
  color: #313a5a;
  background-image: url(https://static.shengri.cn/uploads/QA_mp/ic_tarrow_Btn_read.png?imageslim=);
  background-repeat: no-repeat;
  background-size: 100%;
}

.tarot-play {
  width: 100%;
  height: 95%;
  align-items: center;
  background-image: url(https://static.shengri.cn/uploads/tarotSignIn/guangban.png?imageslim=);
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}


.init-box img {
  width: 160px;
  height: 250px;
  transition: all 1s linear;
  position: absolute;
  left: 40%;
  transform-origin: 50% 100%;
}

.step-content {
  width: 100%;
  height: 100%;
  display: inline-block;
  text-align: center; /* 文字水平居中对齐 */
}

.entrance-box {
  height: 50%;
}

.init-action {
  margin-top: 4rem;
  height: 40%;
}

.desc-content p {
  font-size: 12px;
  color: #fff;
  text-align: center;
  font-weight: 300
}

.shuffle-wrapper {
  width: 10.466667rem;
  height: 10.466667rem;
  /*width: 100%;*/
  /*height: 100%;*/
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url(https://static.shengri.cn/uploads/QA_mp/slim/Circle.gif?imageslim=);
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 1%;
}

.shuffle-wrapper .shuffle-btn {
  width: 4.346667rem;
  height: 4.346667rem;
  line-height: 4.346667rem;
  text-align: center;
  font-size: 16px;
  color: #313a5a;
  background-image: url(https://static.shengri.cn/uploads/QA_mp/ic_tarrow_Btn_read.png?imageslim=);
  background-repeat: no-repeat;
  background-size: 100%
}


.header-img {
  width: 100%;
  height: 10%;
  margin: 1.066667rem auto 0;
  background-image: url(https://static.shengri.cn/uploads/QA_mp/ic_toubuwenzi@3x.png?imageslim=);
  background-repeat: no-repeat;
  background-size: 3.68rem 1.493333rem
}


.header h1 {
  font-size: .426667rem;
  color: #fff
}

.tips {
  white-space: nowrap; /* 防止文字换行 */
  position: absolute;
  color: red;
  right: 100%; /* 初始位置在屏幕右侧外部 */
  animation: scrollText 10s linear infinite; /* 滑动动画，10秒循环 */
}

@keyframes scrollText {
  0% {
    transform: translateX(100%); /* 从右侧进入屏幕 */
  }
  100% {
    transform: translateX(-100%); /* 滑动到屏幕左侧外部 */
  }
}


@keyframes shuffle {
  0% {
    transform: rotate(0deg) scale(1);
  }
  /*50% {*/
  /*  transform: rotate(360deg) scale(0.7);*/
  /*}*/
  100% {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes entranceAni1 {
  0% {
    top: 0;
    transform: rotate(40deg) scale(.8)
  }

  46% {
    top: 0;
    transform: rotate(0) scale(1)
  }

  to {
    top: 20%;
    transform: rotate(0) scale(.5)
  }
}

@keyframes entranceAni2 {
  0% {
    top: 0;
    transform: rotate(20deg) scale(.9)
  }

  46% {
    top: 0;
    transform: rotate(0) scale(1)
  }

  to {
    top: 20%;
    transform: rotate(0) scale(.5)
  }
}

@keyframes entranceAni3 {
  0% {
    top: 0;
    transform: rotate(-40deg) scale(.8)
  }

  46% {
    top: 0;
    transform: rotate(0) scale(1)
  }

  to {
    top: 20%;
    transform: rotate(0) scale(.5)
  }
}

@keyframes entranceAni4 {
  0% {
    top: 0;
    transform: rotate(-20deg) scale(.9)
  }

  46% {
    top: 0;
    transform: rotate(0) scale(1)
  }

  to {
    top: 20%;
    transform: rotate(0) scale(.5)
  }
}

@keyframes entranceAni5 {
  0% {
    top: 0
  }
  46% {
    top: 0;
    transform: rotate(0) scale(1)
  }
  to {
    top: 20%;
    transform: rotate(0) scale(.5)
  }
}

.shuffle-action {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.shuffle-action .shuffle-result {
  width: 7.2rem;
  position: relative;
}

.shuffle-action .shuffle-result .shuffle-driver-ico {
  width: 100%;
  height: 2.026667rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.shuffle-action .shuffle-result .tarot-result {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.shuffle-action .shuffle-result .tarot-result .result-item-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
}

.shuffle-action .shuffle-result .tarot-result .result-item-wrapper .choose-tarot-wrapper {
  width: 4rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  background-image: url(https://static.shengri.cn/uploads/QA_mp/ic_xuxian@3x.png?imageslim=);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-weight: 300;
}

.shuffle-action .shuffle-result .tarot-result .result-item-wrapper .tarot-name {
  margin-top: 0.266667rem;
  text-align: center;
  font-size: .32rem;
  color: #cfbd7a;
  height: 0.373333rem;
}


</style>
