<template>
  <div>
    <h1>缘分是天定的，幸福是自己的。</h1>
    <p>想知道你和 ta 有没有缘分呢，编辑“自己姓名” “对方姓名”，然后点击“一键预测”。</p>
    <p>如郭靖 黄蓉，然后点击一键预测。 就能查看你和 ta 的缘分了。</p><br/>
    <span>自己姓名: </span>
    <el-input
        class="optional"
        maxlength="40"
        v-model="name"
        placeholder="请输入您的名字"
        style="width: 160px"
    ></el-input>
    <br/><br/>
    <span>对方姓名: </span>
    <el-input
        class="optional"
        v-model="name1"
        maxlength="40"
        placeholder="请输入对方的名字"
        style="width: 160px"
    ></el-input>
  </div>

  <fortune-card
      :loading="loading"
      :title="title"
      :bt_name="bt_name"
      :result="result"
      :startEventStream="startEventStream"
  ></fortune-card>
</template>

<script>
import DivinationMixin from './DivinationMixin';
import FortuneCard from '@/components/tarot/FortuneCard.vue';

export default {
  components: {
    FortuneCard
  },
  mixins: [DivinationMixin],
  data() {
    return {
      name:"郭靖",
      name1:"黄蓉",
      result: '',
      title: '占卜结果',
      bt_name: '一键预测',
      tmp_result: '',
      loading: false,
      prompt_type: 'fate',
    }
  },
  methods: {
    startEventStream() {
      this.startEventStreamCommon({
        inputValidation: () => !!this.name && !!this.name1,
        errorMessage: '请输入您和对方的名字',
        createRequestBody: () => ({
          prompt_type: this.prompt_type,
          name: this.name,
          name1: this.name1
        })
      });
    }
  },
}
</script>
<style>

</style>
