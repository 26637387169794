<template>
<!--  <div class="layer-container">-->
<!--   -->
<!--  </div>-->
  <div class="main layer-container">
    <div v-for="i in divSize" :key="i" class="layer" :class="'layer' + i"></div>
    <nav-menu/> <!-- 包含导航栏菜单组件 -->
    <div class="content">
      <router-view/> <!-- 用于渲染不同页面的容器 -->
    </div>
  </div>
</template>

<script>
import NavMenu from "@/navMenu";
export default {
  components: {NavMenu},

  data() {
    return {
      duration: 1500,
      count: 1000,
      isInitialized: false,
      divSize: 4,


    };
  },
  mounted() {
    if (!this.isInitialized) {
      this.initializeLayers();
      this.isInitialized = true;
    }
  },
  methods: {
    initializeLayers() {
      for (let i = 1; i <= this.divSize; i++) {
        this.duration = Math.floor(this.duration * 2);
        this.count = Math.floor(this.count / 2);
        const layer = document.querySelector(`.layer${i}`);
        // layer.style.width = `${Math.floor(Math.random() * 100) }px`; // 控制大小在50~150px之间
        layer.style.width = `${i}px`; // 控制大小在50~150px之间
        layer.style.height = layer.style.width;
        layer.style.boxShadow = this.getShadows(this.count, i);
        layer.style.animationDuration = `${this.duration}ms`;
      }
    },
    getShadows(n, layerIndex) {
      console.log(layerIndex)
      console.log(`${layerIndex} ${n}`)
      let shadows = ''
      for (let i = 1; i <= n; i++) {
        const x = Math.random() * 100;
        const y = Math.random() * 100;
        shadows += `${x}vw ${y}vh #fff`;
        if (i < n) {
          shadows += ', ';
        }
      }
      return shadows;
    }
  },

};
</script>

<style>

.content {
  margin-top: 20px;
}

.fortune-button {
  /*margin-top: 20px;*/
  /*margin-bottom: 20px;*/
  background-color: #ff9900; /* 橙色背景 */
  color: white; /* 白色文字 */
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 10px; /* 圆角按钮 */
  cursor: pointer;
  transition: background-color 0.3s;
}

.fortune-button:hover {
  background-color: #ff6600; /* 鼠标悬停时颜色变化 */
}

.result {
  width: 50%;
  margin-left: 25%;
  margin-top: 20px;
}

.layer-container{
  position: relative;
  width: 100vw;  /* 100% of the viewport width */
  height: 100vh; /* 100% of the viewport height */
  overflow: hidden;
  margin: 0;
  background-color: #000; /* 设置背景色 */
  /*box-sizing: border-box;*/
}

.layer {
  position: absolute;
  border-radius: 50%;
  background: #f40;
  animation: moveup var(--duration) linear infinite;
  transform-origin: center bottom;
  &::after{
   content: '';
   position: fixed;
   width: inherit;
   height: inherit;
   left: 0;
   top: 100vh;
   border-radius: inherit;
   box-shadow: inherit;
 }
}

.layer1 {
  --duration: 1000ms;
}

.layer2 {
  --duration: 500ms;
}

.layer3 {
  --duration: 250ms;
}

.layer4 {
  --duration: 125ms;
}

.layer5 {
  --duration: 62.5ms;
}

@keyframes moveup {
  to {
    transform: translateY(-100vh);
  }
}

.main {
  text-align: center;
  color: #fff; /* 设置主内容文字颜色 */
}
</style>
